<template>    
    <v-container
        fluid
        class="d-flex justify-start minigolf-intro"
    >
        <div class="location-intro-text">
            <p class="location-intro-text-title">Minigolf</p>
            <p class="location-intro-text-subtitle">Nach oder vor dem Essen noch eine Runde Minigolf ?</p>
            <p class="location-intro-text-subtitle">Direkt neben unserem Restaurant bieten wir in unserem Außenbereich eine Minigolf-Bahn an</p>
        </div>
    </v-container>
</template>
<script>
export default {
    data: () => ({

    }),
}

</script>
<style scoped>

</style>